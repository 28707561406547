import React from 'react';

function ProjectsPage() {
  return (
    <div className='mt-20 container mx-auto'>
      <div className="bg-mySecondaryColor mb-3 w-full md:w-3/4 shadow-lg rounded-lg overflow-hidden flex flex-col mx-auto">
        <img className="w-full h-65 object-cover object-center" src={process.env.PUBLIC_URL + '/images/msapa-thumbnail.png'} alt="Msapa Architectural Designs" />
        <div className="p-4 flex-grow flex flex-col justify-between">
          <h2 className="text-gray-900 font-bold text-2xl mb-2">Msapa Architectural Designs</h2>
          <ul className="text-gray-700 text-base list-disc ml-6">
            <li>Optimized website for search engines to improve online visibility</li>
            <li>Added social media icons that link to company's social media pages</li>
            <li>Integrated YouTube playlist directly on the website for easy viewing</li>
            <li>Added Google Maps integration to display company's location and enable visitors to get directions</li>
            <li>Ensured website is fully responsive and functions well on all devices</li>
          </ul>
          <div className="text-center">
            <a href="https://msapa.co.za/" className="inline-block my-3 bg-myTertiaryColor  border border-transparent text-base font-sans font-medium text-mySecondaryColor hover:bg-myPrimaryColor  py-2 px-4 rounded-full transition-colors duration-300">View Website</a>
          </div>      
        </div>
      </div>

      <div className="bg-mySecondaryColor w-full md:w-3/4 shadow-lg rounded-lg overflow-hidden flex flex-col mx-auto">
        <img className="w-full h-65 object-cover object-center" src={process.env.PUBLIC_URL + '/images/mhs-thumbnail.png'} alt="Msapa Architectural Designs" />
        <div className="p-4 flex-grow flex flex-col justify-between">
          <h2 className="text-gray-900 font-bold text-2xl mb-2">Mothudi Healthcare Services</h2>
          <ul className="text-gray-700 text-base list-disc ml-6">
            <li>Responsive Design for all screen sizes</li>
            <li>Copywriting and content creation</li>
            <li>Modern Responsive navigation menu</li>
            <li>Logo Design</li>
            <li>Intergrated with WhatsApp API for booking and customer care</li>
          </ul>
          <div className="text-center">
            <a href="https://main--reliable-travesseiro-bf6ad8.netlify.app/" className="inline-block my-3 bg-myTertiaryColor  border border-transparent text-base font-sans font-medium text-mySecondaryColor hover:bg-myPrimaryColor  py-2 px-4 rounded-full transition-colors duration-300">View Website</a>
          </div>      
        </div>
      </div>
    </div>
  )
}

export default ProjectsPage;
