import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import services from "./services";

function ServicesCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto py-10 px-16 shadow-lg rounded-lg my-4 bg-myPrimaryColor/[.5]" id="services">
      <h2 className="text-3xl text-center font-bold mb-5">Our Services</h2>
      <Slider {...settings}>
        {services.map((service) => (
          <div key={service.title} className="px-4">
            <div className=" rounded-lg shadow-md p-6 h-full">
              <h3 className="text-xl font-bold mb-4">{service.title}</h3>
              <p className="text-mySecondaryColor">{service.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ServicesCarousel;
