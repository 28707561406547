// services.js

const services = [
    {
      id: 1,
      title: 'Web Design',
      description: 'We design and develop custom websites that are both visually stunning and user-friendly. Our team works closely with clients to create a website that represents their brand and meets their business objectives.',
      image: 'web-design.jpg',
    },
    {
      id: 2,
      title: 'Mobile App Development',
      description: 'We create high-quality mobile applications for iOS and Android that are tailored to your specific needs. Our team is experienced in developing native apps as well as hybrid apps that work seamlessly across multiple platforms.',
      image: 'mobile-app-development.jpg',
    },
    {
      id: 3,
      title: 'UI/UX Design',
      description: 'We specialize in creating user interfaces that are both visually appealing and easy to use. Our team works closely with clients to understand their users and create designs that meet their needs.',
      image: 'ui-ux-design.jpg',
    },
    {
      id: 4,
      title: 'E-commerce Solutions',
      description: 'We build online stores that are secure, scalable, and easy to manage. Our e-commerce solutions are designed to help businesses sell more products and increase revenue.',
      image: 'e-commerce-solutions.jpg',
    },
    {
      id: 5,
      title: 'Custom Software Development',
      description: 'We develop custom software solutions that are tailored to your specific needs. Our team has experience in developing software for a variety of industries, including healthcare, finance, and education.',
      image: 'custom-software-development.jpg',
    },
    {
      id: 6,
      title: 'Digital Marketing',
      description: 'We provide digital marketing services to help businesses reach their target audience and increase brand awareness. Our team is experienced in SEO, PPC advertising, social media marketing, and email marketing.',
      image: 'digital-marketing.jpg',
    },
  ];
  
  export default services;
  