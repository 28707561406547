import React from 'react';
import { Link } from 'react-router-dom';

function HeroBanner() {


  return (
    <div
      className="container mt-20 relative bg-gray-800"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/Hero-Banner.png)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className="absolute inset-0 bg-gray-900 opacity-75" aria-hidden="true"></div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-sans font-extrabold tracking-tight text-mySecondaryColor md:text-5xl lg:text-6xl">
          INNOVATE YOUR WORLD!
        </h1>
        <p className="mt-6 font-sans max-w-3xl text-xl text-mySecondaryColor">
          We specialize in creating responsive websites and mobile applications that are optimized for user experience,
          functionality, and design.
        </p>
        <div className="mt-10">
          <Link
            to="/services"
            className="inline-block bg-myTertiaryColor/[.50]  border border-transparent text-base font-sans font-medium text-mySecondaryColor hover:bg-myTertiaryColor  py-2 px-4 rounded-full transition-colors duration-300"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
