import React from 'react';


const Clients = () => {
  return (
    <div className="bg-mySecondaryColor py-8  px-4">
      <div className="container mx-auto shadow-md rounded-md">
      <h2 className="text-3xl font-bold mb-5 text-center">Our Clients</h2>
        <div className="flex flex-wrap justify-center items-center">
          <div className="w-1/4 px-4 py-4">
            <img src={process.env.PUBLIC_URL+"images/clients/msapa.png"} alt="Msapa Architectural Design" className="w-full" />
          </div>

          <div className="w-1/4 px-4 py-4">
            <img src={process.env.PUBLIC_URL+"images/clients/MHS Logo.png"} alt="Msapa Architectural Design" className="w-full" />
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Clients;
