import React from 'react';
import { Link } from 'react-router-dom';

function Overview() {
  return (
    <div className="py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto space-x-4 flex flex-col sm:flex-row items-center">
        <div className="w-full sm:w-1/2 mb-8 sm:mb-0">
          <img src={process.env.PUBLIC_URL + '/images/abtUs.jpg'} alt="About Us" className="rounded-lg shadow-lg" />
        </div>
        <div className="w-full sm:w-1/2">
          <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center">ABOUT US</h2>
          <p className="text-lg text-gray-600 mb-8 text-center">
          We are a technology-based business that specializes in designing and developing responsive websites and mobile applications for businesses of all sizes. 
          </p>
          <div className="flex items-center justify-center">
            <Link
              to="/about"
              className="inline-block bg-myTertiaryColor/[.65] py-3 px-8 border border-transparent rounded-full text-base font-sans font-medium text-mySecondaryColor hover:bg-myTertiaryColor  transition-colors duration-300"
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
