import React from 'react';
import Clients from './Clients';
import HeroBanner from './HeroBanner';
import Overview from './Overview';
import ServicesOverview from './ServicesOverview';
import WebPackagesSection from './WebPackagesSection';

function HomePage() {
  return (
    <div> 
      <HeroBanner/>
      <Overview/>
      <WebPackagesSection/>
      <ServicesOverview/>  
      <Clients/>
    </div>
  )
}

export default HomePage
