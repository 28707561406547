import React from 'react';
import services from "./services";
import WebPackagesSection from "./WebPackagesSection"
import { FaLaptop } from 'react-icons/fa';

function ServicesPage() {
  return (
    <div className="container mx-auto py-10 px-8 mt-20 ">
      <h2 className="text-3xl font-bold mb-5 text-center">Our Services</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {services.map((service) => (
          <div key={service.title} className="bg-mySecondaryColor/[.50] rounded-lg shadow-md p-6 flex flex-col items-center">
            <div className="mb-4">
              <FaLaptop className="h-12 w-12 text-myPrimaryColor shadow-sm p-2 rounded-lg" />
            </div>
            <h3 className="text-2xl font-bold mb-2">{service.title}</h3>
            <p className="text-gray-600 text-center">{service.description}</p>
          </div>
        ))}
      </div>
      <h2 className="text-3xl font-bold my-5 text-center">Featured Website Packages</h2>
      <WebPackagesSection/>
    </div>
  );
}

export default ServicesPage;
