import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="p-8 mt-20">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        Here at SMEECHTECH GENERAL TRADING PTY LTD, we take your privacy very seriously. This Privacy Policy explains how we collect, use, and disclose your personal information.
      </p>
      <h2 className="text-2xl font-bold mb-4">Information We Collect</h2>
      <p className="mb-4">
        We collect personal information that you provide to us, such as your name, email address, phone number, and payment information. We also collect information about how you use our website and services, such as your IP address and browser type.
      </p>
      <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>
      <p className="mb-4">
        We use your personal information to provide you with our website and services, to communicate with you about your account, and to process payments. We may also use your information for marketing purposes, such as sending you newsletters and promotions.
      </p>
      <h2 className="text-2xl font-bold mb-4">How We Share Your Information</h2>
      <p className="mb-4">
        We may share your personal information with third-party service providers that help us provide our website and services, such as hosting providers and payment processors. We may also share your information with law enforcement agencies or other third parties if we are required to do so by law or if we believe that it is necessary to protect our rights or the rights of others.
      </p>
      <h2 className="text-2xl font-bold mb-4">Security</h2>
      <p className="mb-4">
        We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. However, no security system is completely foolproof, and we cannot guarantee the security of your information.
      </p>
      <h2 className="text-2xl font-bold mb-4">Changes to This Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time, and the latest version will be posted on our website. By continuing to use our website or services, you agree to the updated Privacy Policy.
      </p>
      <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy, please contact us at info@smeechtech.co.za.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
