import React from "react";
import webPackages from "./webPackages";

function sendMessage(packageTitle) {
  const message = `Hello, I would like to know more about the ${packageTitle}.`;
  const phone = "+27662217240"; // replace with your own phone number
  const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(message)}`;
  window.open(url, "_blank");
}

function WebPackagesSection() {
  return (
    <div className="flex flex-wrap justify-center items-center">
      {webPackages.map((webPackage) => (
        <div
          key={webPackage.id}
          className="max-w-md mx-4 rounded-lg overflow-hidden shadow-lg bg-mySecondaryColor/[.50] my-4"
        >
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{webPackage.name}</div>
            {webPackage.pages && (
              <p className="text-gray-700 text-base">{webPackage.pages}</p>
            )}
            <p className="text-gray-700 text-base">{webPackage.design}</p>
            <p className="text-gray-700 text-base">{webPackage.responsiveness}</p>
            <p className="text-gray-700 text-base">{webPackage.seo}</p>
            <p className="text-gray-700 text-base">{webPackage.revisions} revisions</p>
            {webPackage.formIntegration && (
              <p className="text-gray-700 text-base">{webPackage.formIntegration}</p>
            )}
            {webPackage.analytics && (
              <p className="text-gray-700 text-base">{webPackage.analytics}</p>
            )}
            {webPackage.socialMediaIntegration && (
              <p className="text-gray-700 text-base">{webPackage.socialMediaIntegration}</p>
            )}
            {webPackage.productCatalog && (
              <p className="text-gray-700 text-base">{webPackage.productCatalog}</p>
            )}
            {webPackage.paymentGatewayIntegration && (
              <p className="text-gray-700 text-base">{webPackage.paymentGatewayIntegration}</p>
            )}
            {webPackage.shoppingCart && (
              <p className="text-gray-700 text-base">{webPackage.shoppingCart}</p>
            )}
            {webPackage.orderManagementSystem && (
              <p className="text-gray-700 text-base">{webPackage.orderManagementSystem}</p>
            )}
            {webPackage.hosting && (
              <p className="text-gray-700 text-base">{webPackage.hosting}</p>
            )}

            <div className="font-bold text-xl mb-2 text-center mt-4">
              Starting from R{webPackage.price}.00 
            </div>

          </div>
          <div className="px-6 py-4">
            <button
              className="bg-myTertiaryColor/[.65] hover:bg-myTertiaryColor text-mySecondaryColor font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
              onClick={() => sendMessage(webPackage.name)}
            >
              Contact Us
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default WebPackagesSection;
