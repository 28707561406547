import React from 'react';
import { FaTwitter, FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import ContactForm from './ContactForm';

function Footer() {
  return (
    <footer className="bg-myPrimaryColor text-mySecondaryColor py-8 ">
      <div className="container mx-auto flex flex-col md:flex-row justify-evenly items-center">
        <div className="flex justify-center md:justify-end mt-4 md:mt-0">
          <ContactForm />
        </div>
        <div className="flex flex-col items-center md:items-start">
            <div className="flex sm:mt-2 md:mt-0">
                <img src={process.env.PUBLIC_URL + "images/logo-transparent.svg"} alt="Smeechtech logo" className="h-10 mr-4 rounded-full p-1 bg-mySecondaryColor" />
                <p className="text-lg font-bold mb-2">SMEECHTECH</p>
            </div>  
            <p className="mb-2">Phone: (+27)662217240</p>
            <p>Email: info@smeechtech.co.za</p>
        </div>
        <div className="flex justify-center md:justify-end mt-4 md:mt-0">
          <ul className="">
            <li className="mb-2"><a href="/">Home</a></li>
            <li className="mb-2"><a href="/about">About</a></li>
            <li className="mb-2"><a href="/services">Services</a></li>
          </ul>
        </div>
        <div className="flex justify-center md:justify-end mt-4 md:mt-0">
            <ul>
                <li className="mb-2"><a href="/privacy-policy">Privacy Policy</a></li>
                <li className="mb-2"><a href="/terms-of-service">Terms of Service</a></li>
            </ul>
        </div>

      </div>
      <div className="  border-t border-mySecondaryColor mt-8 pt-4 flex justify-evenly items-center">
        <p>&copy; 2017- SMEECHTECH. All rights reserved.</p>
        <div className="flex">
          <a href="https://wa.me/27662217240" className="mr-4"><FaWhatsapp size={32} className="text-mySecondaryColor hover:text-green-500" /></a>
          <a href="https://twitter.com/SMEECHTECH_SA" className="mr-4"><FaTwitter size={32} className="text-mySecondaryColor hover:text-sky-500" /></a>
          <a href="https://facebook.com/smeechtech" className="mr-4"><FaFacebook size={32} className="text-mySecondaryColor hover:text-blue-500"/></a>
          <a href="https://instagram.com/smeechtech" className="mr-4"><FaInstagram size={32} className="text-mySecondaryColor hover:text-pink-500" /></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
