import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import GetQuoteModal from './GetQuoteModal';

function Navbar() {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false); // Add state for modal
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  

  const handleOpenModal = () => {
    setIsModalOpen(true); // Open modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <nav className="bg-mySecondaryColor flex flex-wrap items-center space-x-4 fixed z-30 justify-between w-full py-4 px-6">
      {/* Logo */}
      <Link to="/" className="text-xl font-bold">
      <img
                  className=" block object-cover object-center w-14 h-14"
                  src={process.env.PUBLIC_URL+"images/logo-transparent.svg"}
                  alt="SMEECHTECH GENERAL TRADING"
                  loading="lazy"
                />
      </Link>

      {/* Hamburger menu */}
      <div className="flex md:hidden">
        <button
          type="button"
          className="text-gray-800 hover:text-gray-700 focus:outline-none focus:text-gray-700"
          aria-label="Toggle menu"
          onClick={toggleExpanded}
        >
          <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
            {isExpanded ? (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 8h14v2H5V8zm0 5h14v2H5v-2z"
              />
            ) : (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z"
              />
            )}
          </svg>
        </button>
      </div>

      {/* Navigation links */}
      <ul
        className={`${
          isExpanded ? 'block' : 'hidden'
        } md:flex md:flex-row text-center md:items-center space-x-4 w-full md:w-auto text-black`}
      >
        <li>
          <Link
            to="/"
            className={`block px-4 py-2 md:p-2 hover:border-b-2 rounded-md shadow-sm border-myTertiaryColor ${
              location.pathname === '/' ? 'border-b-2 border-myTertiaryColor' : ''
            }`}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/services"
            className={`block px-4 py-2 md:p-2 hover:border-b-2 rounded-md shadow-sm border-myTertiaryColor ${
              location.pathname === '/services' ? 'border-b-2 border-myTertiaryColor' : ''
            }`}
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            to="/projects"
            className={`block px-4 py-2 md:p-2 hover:border-b-2 rounded-md shadow-sm border-myTertiaryColor ${
              location.pathname === '/projects' ? 'border-b-2 border-myTertiaryColor' : ''
            }`}
          >
            Projects
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={`block px-4 py-2 md:p-2 hover:border-b-2 rounded-md shadow-sm border-myTertiaryColor ${
              location.pathname === '/about' ? 'border-b-2 border-myTertiaryColor' : ''
            }`}
          >
            About
          </Link>
        </li>
      </ul>

      {/* Request for a quotation button */}
      <button onClick={handleOpenModal} className={`${
          isExpanded ? 'block' : 'hidden'
        } mt-2 md:mt-0 bg-myTertiaryColor text-mySecondaryColor font-medium py-2 px-4 rounded shadow hover:bg-myPrimaryColor hover:text-mySecondaryColor md:ml-4 md:flex md:flex-row md:items-center w-full md:w-auto`}>
        Get Quote
      </button>
      <GetQuoteModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </nav>
  );
}

export default Navbar;
