import React from 'react';

const TermsOfService = () => {
  return (
    <div className="p-8 mt-20">
      <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
      <p className="mb-4">
        These terms of service outline the rules and regulations for using our website and services. By accessing our website or using our services, you agree to comply with these terms of service.
      </p>
      <h2 className="text-2xl font-bold mb-4">Use of Our Services</h2>
      <p className="mb-4">
        We provide website design and development services as well as mobile application development services. You may use our services only for lawful purposes and in accordance with these terms of service.
      </p>
      <h2 className="text-2xl font-bold mb-4">Intellectual Property Rights</h2>
      <p className="mb-4">
        All content and materials included in our website or services, such as text, graphics, logos, images, and software, are the property of SMEECHTECH GENERAL TRADING PTY LTD or its content suppliers and protected by applicable copyright and trademark laws. You may not copy, reproduce, distribute, or create derivative works from our content or materials without our prior written consent.
      </p>
      <h2 className="text-2xl font-bold mb-4">Payment and Fees</h2>
      <p className="mb-4">
        We offer various payment options for our services, such as credit card and bank transfer. You agree to pay all fees and charges associated with your use of our services. All fees and charges are non-refundable, except as expressly provided in our refund policy.
      </p>
      <h2 className="text-2xl font-bold mb-4">Limitation of Liability</h2>
      <p className="mb-4">
        SMEECHTECH GENERAL TRADING PTY LTD shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website or services, even if we have been advised of the possibility of such damages. Our total liability to you for any and all claims arising out of or in connection with these terms of service or your use of our website or services shall not exceed the amount paid by you for our services.
      </p>
      <h2 className="text-2xl font-bold mb-4">Termination</h2>
      <p className="mb-4">
        We may terminate your access to our website or services at any time, without cause or notice. Upon termination, you must immediately cease all use of our website and services.
      </p>
      <h2 className="text-2xl font-bold mb-4">Changes to These Terms</h2>
      <p className="mb-4">
        We may update these terms of service from time to time, and the latest version will be posted on our website. By continuing to use our website or services, you agree to the updated terms of service.
      </p>
      <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about these terms of service, please contact us at info@smeechtech.co.za.
      </p>
    </div>
  );
};

export default TermsOfService;
