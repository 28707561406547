const webPackages = [
  {
    id: 1,
    name: "Basic Website Package",
    pages: "SPA(Up to 5 page folds)",
    design: "Custom-designed",
    responsiveness: "Mobile responsive",
    seo: "Basic SEO optimization",
    revisions: 2,
    formIntegration: "Contact form integration",
    hosting: "Website hosting setup",
    price: 6500
  },
  {
    id: 2,
    name: "Standard Website Package",
    pages: "5 to 8 Pages",
    design: "Custom-designed",
    responsiveness: "Mobile responsive",
    seo: "Advanced SEO optimization",
    revisions: 3,
    formIntegration: "Contact form integration",
    analytics: "Google Analytics setup",
    socialMediaIntegration: "Social media integration",
    hosting: "Website hosting setup",
    price: 12000
  },
  {
    id: 3,
    name: "E-commerce Website Package",
    design: "Custom-designed",
    responsiveness: "Mobile responsive",
    seo: "Advanced SEO optimization",
    revisions: 5,
    productCatalog: "Product catalog setup",
    paymentGatewayIntegration: "Payment gateway integration",
    shoppingCart: "Shopping cart setup",
    orderManagementSystem: "Order management system setup",
    analytics: "Google Analytics setup",
    hosting: "Website hosting setup",
    price: 25000
  }
];

export default webPackages;
