import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const GetQuoteModal = ({ isOpen, onClose, onSubmit }) => {
  
  const [formData, setFormData] = useState({
    businessName: '',
    email:'',
    industry: '',
    productsServices: '',
    primaryPurpose: '',
    goals: '',
    features: '',
    targetAudience: '',
    demographicsInterests: '',
    painPointsChallenges: '',
    brandingGuidelines: '',
    preferredWebsites: '',
    logo: '',
    responsibleForContent: '',
    existingContent: '',
    contentType: '',
    contentManagementSystem: '',
    integrationsAndTools: '',
    hostingDomainRequirements: '',
    launchDate: '',
    budget: '',
    paymentSchedule: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const url = "https://smeechtech.herokuapp.com" || "http://localhost:5000"; // Use the Netlify URL if available, otherwise use localhost
    try {
      const response = await fetch(`${url}/get-quote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        // Handle success case
        console.log('Form submitted successfully!');
        setFormData({
          businessName: '',
          email:'',
          industry: '',
          productsServices: '',
          primaryPurpose: '',
          goals: '',
          features: '',
          targetAudience: '',
          demographicsInterests: '',
          painPointsChallenges: '',
          brandingGuidelines: '',
          preferredWebsites: '',
          logo: '',
          responsibleForContent: '',
          existingContent: '',
          contentType: '',
          contentManagementSystem: '',
          integrationsAndTools: '',
          hostingDomainRequirements: '',
          launchDate: '',
          budget: '',
          paymentSchedule: '',
        });
        onClose();
        alert('Quotation request submitted successfully!');
      } else {
        // Handle error case
        console.error('Error submitting form:', response.statusText);
        alert('Error submitting form: ' + response.statusText);
      }
    } catch (error) {
      // Handle network error
      console.error('Network error submitting form:', error);
      alert('Network error submitting form: ' + error.message);
    }
  };
  

  return (
    <>
     
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={onClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block max-w-lg w-full p-6 mb-8 mt-20 overflow-y-auto text-left align-middle transition-all transform bg-mySecondaryColor shadow-xl rounded">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-sans leading-6 text-gray-900"
                >
                  Quotation Questionnaire
                </Dialog.Title>
                <Dialog.Description
                  as="p"
                  className="text-sm font-sans py-4">
                    Please fill out all the required fields in the form below. Thank you!
                </Dialog.Description>
                <form className="mt-6" onSubmit={handleSubmit}>
                  <Dialog.Description
                    className="text-large mb-2"
                    as="h4">
                    About the client:
                  </Dialog.Description>
                  <div className="grid grid-cols-1 gap-6">
                    <div>
                      <label
                        htmlFor="businessName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Business/Organization Name
                      </label>
                      <input
                        type="text"
                        name="businessName"
                        id="businessName"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.businessName}
                        onChange={handleInputChange}
                        />
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.email}
                        onChange={handleInputChange}
                        />
                    </div>

                    <div>
                      <label
                        htmlFor="industry"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Industry
                      </label>
                      <input
                        type="text"
                        name="industry"
                        id="industry"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.industry}
                        onChange={handleInputChange}
                      />
                    </div>

                <div>
                  <label
                    htmlFor="productsServices"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Products/Services
                  </label>
                  <input
                    type="text"
                    name="productsServices"
                    id="productsServices"
                    autoComplete="off"
                    required
                    className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                    value={formData.productsServices}
                    onChange={handleInputChange}
                  />
                </div>
                <Dialog.Description
                    className="text-large my-2"
                    as="h4">
                    Purpose and goals:
                </Dialog.Description>
                <div>
                      <label
                        htmlFor="primaryPurpose"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Primary purpose of your website
                      </label>
                      <input
                        type="text"
                        name="primaryPurpose"
                        id="primaryPurpose"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.primaryPurpose}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="goals"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Goals to achieve with the website
                      </label>
                      <input
                        type="text"
                        name="goals"
                        id="goals"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.goals}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="features"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Specific features and functionality desired
                      </label>
                      <input
                        type="text"
                        name="features"
                        id="features"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.features}
                        onChange={handleInputChange}
                      />
                    </div>

                    <Dialog.Description
                      className="text-large my-2"
                      as="h4">
                      Target audience:
                    </Dialog.Description>

                    <div>
                      <label
                        htmlFor="targetAudience"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Target audience
                      </label>
                      <input
                        type="text"
                        name="targetAudience"
                        id="targetAudience"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.targetAudience}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="demographicsInterests"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Demographics and interests
                      </label>
                      <input
                        type="text"
                        name="demographicsInterests"
                        id="demographicsInterests"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.demographicsInterests}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="painPointsChallenges"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Pain points and challenges
                      </label>
                      <input
                        type="text"
                        name="painPointsChallenges"
                        id="painPointsChallenges"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.painPointsChallenges}
                        onChange={handleInputChange}
                      />
                    </div>
                    
                    <Dialog.Description
                      className="text-large my-2"
                      as="h4">
                      Branding and design:
                    </Dialog.Description>

                    <div>
                      <label
                        htmlFor="brandingGuidelines"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Branding guidelines or design preferences
                      </label>
                      <input
                        type="text"
                        name="brandingGuidelines"
                        id="brandingGuidelines"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.brandingGuidelines}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="preferredWebsites"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Preferred websites or designs and reasons why
                      </label>
                      <input
                        type="text"
                        name="preferredWebsites"
                        id="preferredWebsites"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.preferredWebsites}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="logo"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Logo or existing graphics to include
                      </label>
                      <input
                        type="text"
                        name="logo"
                        id="logo"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.logo                                                                                                                                                       }
                        onChange={handleInputChange}
                      />
                    </div>

                    <Dialog.Description
                      className="text-large my-2"
                      as="h4">
                      Content creation and management:
                    </Dialog.Description>

                    <div>
                      <label
                        htmlFor="responsibleForContent"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Responsible for creating and managing content
                      </label>
                      <input
                        type="text"
                        name="responsibleForContent"
                        id="responsibleForContent"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.responsibleForContent}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="existingContent"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Existing content to migrate
                      </label>
                      <input
                        type="text"
                        name="existingContent"
                        id="existingContent"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.existingContent}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="contentType"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Type of content (text, images, videos)
                      </label>
                      <input
                        type="text"
                        name="contentType"
                        id="contentType"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.contentType}
                        onChange={handleInputChange}
                      />
                    </div>

                    <Dialog.Description
                      className="text-large my-2"
                      as="h4">
                      Technical requirements:
                    </Dialog.Description>

                    <div>
                      <label
                        htmlFor="contentManagementSystem"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Preferred content management system
                      </label>
                      <input
                        type="text"
                        name="contentManagementSystem"
                        id="contentManagementSystem"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.contentManagementSystem}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="integrationsAndTools"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Integrations and third-party tools needed
                      </label>
                      <input
                        type="text"
                        name="integrationsAndTools"
                        id="integrationsAndTools"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.integrationsAndTools}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="hostingDomainRequirements"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Hosting or domain requirements
                      </label>
                      <input
                        type="text"
                        name="hostingDomainRequirements"
                        id="hostingDomainRequirements"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.hostingDomainRequirements}
                        onChange={handleInputChange}
                      />
                    </div>

                    <Dialog.Description
                      className="text-large my-2"
                      as="h4">
                      Timeline and budget:
                    </Dialog.Description>

                    <div>
                      <label
                        htmlFor="launchDate"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Desired launch date
                      </label>
                      <input
                        type="date"
                        name="launchDate"
                        id="launchDate"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.launchDate}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="budget"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Budget for the project in ZAR
                      </label>
                      <input
                        type="number"
                        name="budget"
                        id="budget"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.budget}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="paymentSchedule"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Payment schedule preference
                      </label>
                      <input
                        type="text"
                        name="paymentSchedule"
                        id="paymentSchedule"
                        autoComplete="off"
                        required
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 bg-mySecondaryColor rounded-md"
                        value={formData.paymentSchedule}
                        onChange={handleInputChange}
                      />
                    </div>

          

                {/* ... End of questionaire ... */}

              </div>
              <div className="mt-8">
                <button
                  type="submit"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-mySecondaryColor bg-myTertiaryColor border border-transparent rounded-md hover:bg-myPrimaryColor focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="ml-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
</>
);
};

export default GetQuoteModal;
