import React, { useState } from 'react';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState('');

  function handleSubmit(event) {
    event.preventDefault();
  
    const data = {
      name: name,
      email: email,
      message: message
    };
    const url = "https://smeechtech.herokuapp.com" || "http://localhost:5000"; // Use the custom URL if available, otherwise use localhost
    fetch(`${url}/contact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          console.log('Success:', response);
          setSubmissionStatus('success');
          setName('');
          setEmail('');
          setMessage('');
        } else {
          console.error('Error:', response);
          setSubmissionStatus('error');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setSubmissionStatus('error');
      });
  }
  
  

  return (
    <form onSubmit={handleSubmit}
      className="flex flex-col gap-3">
      <h3 className="font-sans text-lg font-bold mb-2">Talk to us:</h3>
      <label htmlFor="name">Name:</label>
      <input
        id="name"
        name="name"
        type="text"
        className="bg-mySecondaryColor rounded-lg text-gray-800"
        value={name}
        onChange={event => setName(event.target.value)}
        required
      />
      <label htmlFor="email">Email:</label>
      <input
        id="email"
        name="email"
        type="email"
        className="bg-mySecondaryColor rounded-lg text-gray-800"
        value={email}
        onChange={event => setEmail(event.target.value)}
        required
      />
      <label htmlFor="message">Message:</label>
      <textarea
        id="message"
        name="message"
        className="bg-mySecondaryColor rounded-lg text-gray-800"
        value={message}
        onChange={event => setMessage(event.target.value)}
        required
      />
      <button className="bg-myTertiaryColor hover:bg-myPrimaryColor rounded-lg shadow-sm w-1/2 px-3 py-2" type="submit">Send</button>
      {submissionStatus === 'success' && (
        <div className="text-mySecondaryColor flex flex-wrap">
          Message sent successfully
        </div>
      )}
      {submissionStatus === 'error' && (
        <div className="text-red-600 flex flex-wrap">
          Something went wrong. Please try again later.
        </div>
      )}

    </form>
  );
}

export default ContactForm;
