import React from 'react';


function AboutPage() {
  return (
    <div className="bg-mySecondaryColor mt-20">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <img className="mx-auto h-12 w-auto" src={process.env.PUBLIC_URL+"images/logo-transparent.svg"} alt="Smeechtech logo" />
          <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            About Smeechtech
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            SMEECHTECH GENERAL TRADING PTY LTD is a technology-based business that specializes in designing and developing responsive websites and mobile applications for businesses of all sizes. Our team consists of experienced web designers, developers, and project managers who have a proven track record of delivering high-quality projects on time and on budget. Our clients range from small startups to large corporations across a variety of industries.
          </p>
        </div>
        <div className="mt-10">
          <h3 className="text-center text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            Meet Our Team
          </h3>
          <div className="mt-5 flex flex-wrap justify-center text-mySecondaryColor">
            <div className="max-w-md container mx-auto bg-myPrimaryColor rounded-xl shadow-md overflow-hidden md:max-w-2xl">
              <div className="md:flex">
                <div className="md:flex-shrink-0">
                  <img className="h-48 w-full object-contain md:h-auto md:w-48" src={process.env.PUBLIC_URL+"images/smeechy.png"} alt="Mandla'ke Makondo" />
                </div>
                <div className="p-8">
                  <div className="uppercase tracking-wide text-sm text-myTertiaryColor font-semibold">Founder & CEO</div>
                  <h4 className="mt-1 text-xl font-semibold ">Mandla'ke Makondo</h4>
                  <p className="mt-2 ">Mandla'ke is the sole full-time employee of SMEECHTECH GENERAL TRADING PTY LTD. He has a wealth of experience in web development and project management, and he hires contractors as needed to support his workflow and fill gaps in his skill set.</p>
                </div>
              </div>
              <div className="w-full p-3 my-3 border-t-2 border-mySecondaryColor border-spacing-1 flex flex-col justify-center items-center">
                <h4 className=" my-1 text-xl font-semibold ">Skills & Tech Stack</h4>
                <ul className="list-outside list-disc px-3 text-sm font-sans">
                  <li className="list-item">
                   In-depth knowledge of JavaScript, PHP, CSS, HTML and JSX front-end languages.
                  </li>
                  <li className="list-item">
                    In-depth knowledge of Programming languages like C++, Java, Visual Basic, C# an Python.
                  </li>
                  <li className="list-item">
                    Experience with front-end frameworks and tools like  Reactjs, React-Native, BootstrapCss, TailwindCss, Materialize, jQuery, Webpack, Figma and Canva.
                  </li>
                  <li className="list-item">
                    Experience with back-end frameworks and tools like  Node.js, Express.js, Laravel, ASP.NET and Django.
                  </li>
                  <li className="list-item">
                    Solid knowledge of git and git commands.
                  </li>
                  <li className="list-item">
                    Experience with Relational Database Management System like MYSQL.               
                  </li>
                  <li className="list-item">
                    Experience with Document-Oriented Database Management System like MongoDB.               
                  </li>
                  <li className="list-item">
                    Experience with browser-based debugging and perfomance testing software.
                  </li>
                  <li className="list-item">
                    Experience with terminal/command-line debugging.
                  </li>
                  <li className="list-item">
                    Experience with Intergrated Development Environments(IDEs) like Visual Studio, Android Studio, Eclipse and IntelliJ IDEA.
                  </li>
                  <li className="list-item">
                    Experience with code editors like Visual Studio Code, Sublime Text and Notepad++
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
