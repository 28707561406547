import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import ServicesPage from './components/ServicesPage';
import ProjectsPage from './components/ProjectsPage';
import AboutPage from './components/AboutPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';


function App() {
  return (
    <Router>
      <div className="min-h-screen bg-mySecondaryColor">
        {/* Navbar */}
        <Navbar />

        {/* Page content */}
        <div className="container mx-auto py-8">
          <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/services" element={<ServicesPage/>}/>
            <Route path="/projects" element={<ProjectsPage/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path="/terms-of-service" element={<TermsOfService/>}/>
          </Routes>
        </div>
        {/* Footer */}
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
